import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from 'components/Authentication/AuthContext';
import AppRoutes from './AppRoutes';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Styles pour masquer la barre de défilement
const hideScrollbarStyles = `
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;


const App: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Ajouter les styles pour masquer la barre de défilement
    const styleElement = document.createElement('style');
    styleElement.textContent = hideScrollbarStyles;
    document.head.appendChild(styleElement);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsSidebarOpen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Lightzino | Crypto Casino</title>
        <meta name="description" content="The future of crypto gambling." />
        <meta charSet="utf-8" />
        <link rel="icon" href="/assets/icon.png" />
      </Helmet>
      
      <AppRoutes 
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        isMobile={isMobile}
      />
    </>
  );
};


export default App;