import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Assurez-vous d'installer react-helmet-async
import Home from './pages/Home';
import PageWheelHome from 'Routes/SpecialEvents/MagicWheel/Wheel';
import ComingSoonPage from 'pages/Maintenance';

interface AppRoutesProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}


interface PageMetadata {
  title: string;
  description: string;
  keywords?: string;
  ogImage?: string;
}


const PageMetadata: React.FC<PageMetadata> = ({ title, description, keywords, ogImage }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}
    </Helmet>
  );
};

const AppRoutes: React.FC<AppRoutesProps> = ({ isSidebarOpen, setIsSidebarOpen, isMobile }) => {
  return (
    <Routes>
      <Route 
        path="/" 
        element={
          <>
            <PageMetadata 
              title="Coming Soon | Lightzino"
              description="The future of crypto gambling."
              keywords="casino, crypto, crypto-casino, bet, gambling"
              ogImage="/assets/icon.png"
            />
            <ComingSoonPage />
          </>
        } 
      />
      
    


    </Routes>
  );
};

export default AppRoutes;