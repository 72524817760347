import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Timer, ChevronRight, Bitcoin } from 'lucide-react';

const ComingSoonPage = () => {
 
  return (
    <div className="min-h-screen bg-[#1A1E23] flex flex-col items-center justify-center relative overflow-hidden">
      <motion.div
        className="absolute inset-0 opacity-20"
        animate={{
          background: [
            'radial-gradient(circle at 50% 50%, #F20064 0%, transparent 50%)',
            'radial-gradient(circle at 50% 50%, #F20064 20%, transparent 70%)',
            'radial-gradient(circle at 50% 50%, #F20064 0%, transparent 50%)',
          ],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />


      {/* Logo */}
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
        className="mb-12"
      >
        <img src="/assets/logo4.png" alt="Casino Logo" className="w-48 h-auto" />
      </motion.div>

      {/* Main title */}
      <motion.h1
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.8 }}
        className="text-5xl md:text-7xl font-extrabold text-white text-center mb-6"
      >
        THE FUTURE OF
      </motion.h1>
      <motion.h1
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
        className="text-5xl md:text-7xl font-extrabold text-[#F20064] text-center mb-6"
      >
        CRYPTO GAMBLING
      </motion.h1>

      {/* Tagline */}
      <motion.p
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.7, duration: 0.8 }}
        className="text-xl md:text-2xl text-gray-300 text-center mb-12 px-4 max-w-2xl"
      >
        Experience the next generation of online gambling where innovation meets entertainment
      </motion.p>

    

      {/* Newsletter signup */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1.1, duration: 0.8 }}
        className="w-full max-w-md px-4"
      >
        <div className="relative">
          <input
            type="email"
            placeholder="Enter your email for newsletter"
            className="w-full bg-[#1C1F26] text-white px-4 py-4 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#F20064] transition-all pr-36"
          />
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="absolute right-2 top-2 bg-[#F20064] text-white px-4 py-2 rounded-lg font-medium flex items-center gap-2 shadow-[0_0_20px_rgba(242,0,100,0.3)]"
          >
            Notify Me <ChevronRight size={16} />
          </motion.button>
        </div>
      </motion.div>

      {/* Social proof */}
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1.3, duration: 0.8 }}
        className="mt-12 flex items-center gap-2 text-gray-400"
      >
      </motion.div>
    </div>
  );
};

export default ComingSoonPage;